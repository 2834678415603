import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header className="bg-white shadow-md">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                    <img src="/cardscout-logo.jpg" alt="CardScout Logo" className="h-16 w-auto" />
                    <span className="ml-4 text-2xl font-bold text-gray-900">CardScout</span>
                </Link>
                <div className="hidden md:flex space-x-4">
                    <Link to="/smart-buyback" className="text-gray-700 hover:text-blue-600">Smart Buyback</Link>
                    <Link to="/ai-grading" className="text-gray-700 hover:text-blue-600">AI Grading</Link>
                    <Link to="/trend-analyzer" className="text-gray-700 hover:text-blue-600">Trend Analyzer</Link>
                    <Link to="/pricing" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300">
                        Pricing
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default Header;