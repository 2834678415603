import React from 'react';
import { Camera, CheckCircle, AlertCircle, Star } from 'lucide-react';

const AIGradingPredictorPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-20 px-4">
            <div className="max-w-6xl mx-auto">
                <div className="flex justify-center items-center mb-8">
                    <Star className="text-yellow-400 h-10 w-10 mr-2" />
                    <h1 className="text-4xl font-bold text-center text-gray-900">PSA AI Grading Predictor</h1>
                    <Star className="text-yellow-400 h-10 w-10 ml-2" />
                </div>

                <div className="mb-12 text-center">
                    <p className="text-xl text-gray-600">Get instant AI-powered PSA grade predictions for your sports cards.</p>
                    <p className="text-lg text-blue-600 font-semibold mt-2">Exclusive Pro Feature</p>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12 relative overflow-hidden">
                    <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center z-10">
                        <div className="bg-red-600 text-white text-4xl font-bold py-4 px-8 rotate-45 transform translate-y-10 translate-x-10 shadow-lg">
                            Coming Soon
                        </div>
                    </div>

                    <div className="grid md:grid-cols-3 gap-6">
                        <div className="bg-gray-100 p-4 rounded-lg relative">
                            <img src="/api/placeholder/200/300" alt="Card placeholder" className="w-full h-auto rounded-lg shadow-md" />
                        </div>
                        <div className="md:col-span-2 bg-gray-50 p-6 rounded-lg">
                            <h3 className="text-2xl font-bold mb-4 text-center">PSA AI Grade Report</h3>
                            <div className="mb-6 text-center">
                                <p className="text-6xl font-bold text-blue-600">9</p>
                                <p className="text-lg text-gray-600">Predicted PSA Grade</p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <GradeCategory name="Centering" score={90} />
                                <GradeCategory name="Corners" score={85} />
                                <GradeCategory name="Edges" score={95} />
                                <GradeCategory name="Surface" score={90} />
                            </div>
                            <div className="mt-6 text-center">
                                <p className="text-sm text-gray-600">AI Confidence: 92%</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 gap-8 mb-12">
                    <FeatureSection
                        icon={<CheckCircle className="h-8 w-8 text-green-600" />}
                        title="PSA Grading Scale"
                        items={[
                            { grade: 10, label: "Gem Mint" },
                            { grade: 9, label: "Mint" },
                            { grade: 8, label: "Near Mint-Mint" },
                            { grade: 7, label: "Near Mint" },
                            { grade: 6, label: "Excellent-Near Mint" },
                            { grade: 5, label: "Excellent" }
                        ]}
                    />
                    <FeatureSection
                        icon={<AlertCircle className="h-8 w-8 text-orange-600" />}
                        title="Tips for Best Results"
                        items={[
                            "Use high-resolution images",
                            "Ensure good, even lighting",
                            "Capture the entire card in frame",
                            "Take clear photos of both front and back"
                        ]}
                    />
                </div>

                <div className="bg-blue-600 text-white rounded-lg shadow-lg p-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">Ready to Access AI Grading?</h2>
                    <p className="text-xl mb-6">Upgrade to a Pro membership to unlock this powerful tool and more!</p>
                    <button className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
                        See Pricing & Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

const GradeCategory = ({ name, score }) => (
    <div>
        <h4 className="font-semibold mb-2">{name}</h4>
        <div className="bg-gray-200 rounded-full h-4">
            <div className="bg-green-500 h-4 rounded-full" style={{ width: `${score}%` }}></div>
        </div>
        <p className="text-right mt-1">{score >= 90 ? "Excellent" : "Very Good"}</p>
    </div>
);

const FeatureSection = ({ icon, title, items }) => (
    <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 flex items-center">
            {icon}
            <span className="ml-2">{title}</span>
        </h2>
        <ul className="space-y-2">
            {items.map((item, index) => (
                <li key={index} className="flex items-center">
                    {typeof item === 'string' ? (
                        <>
                            <Camera className="mr-2 text-blue-600" />
                            <span>{item}</span>
                        </>
                    ) : (
                        <>
                            <span className={`w-8 h-8 rounded-full bg-blue-${item.grade * 100} text-white flex items-center justify-center mr-2`}>
                                {item.grade}
                            </span>
                            <span>{item.label}</span>
                        </>
                    )}
                </li>
            ))}
        </ul>
    </div>
);

export default AIGradingPredictorPage;