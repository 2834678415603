import React from 'react';
import { BarChart2, TrendingUp, Search, AlertCircle, Star, ArrowRight } from 'lucide-react';

const TrendAnalyzerPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-20 px-4">
            <div className="max-w-6xl mx-auto">
                <div className="flex justify-center items-center mb-8">
                    <Star className="text-yellow-400 h-10 w-10 mr-2" />
                    <h1 className="text-4xl font-bold text-center text-gray-900">Trend Analyzer</h1>
                    <Star className="text-yellow-400 h-10 w-10 ml-2" />
                </div>

                <div className="mb-12 text-center">
                    <p className="text-xl text-gray-600">Discover emerging trends and make data-driven investment decisions.</p>
                    <p className="text-lg text-blue-600 font-semibold mt-2">Exclusive Pro Feature</p>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12 relative overflow-hidden">
                    <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center z-10">
                        <div className="bg-red-600 text-white text-4xl font-bold py-4 px-8 rotate-45 transform translate-y-10 translate-x-10 shadow-lg">
                            Coming Soon
                        </div>
                    </div>

                    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Market Trend Analysis</h2>
                    <div className="mb-6">
                        <div className="flex items-center mb-4">
                            <input
                                type="text"
                                placeholder="Enter player name, card type, or product"
                                className="flex-grow px-4 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button className="bg-blue-600 text-white px-6 py-2 rounded-r-md hover:bg-blue-700 transition duration-300">
                                <Search className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-6">
                        <TrendCard title="Price Trend: Bryce Young Prizm RC" chart="/api/placeholder/400/200" />
                        <MarketSentimentCard />
                        <RecentSalesCard />
                        <AIRecommendationsCard />
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Our Advanced Algorithm</h2>
                    <p className="text-gray-600 mb-4">
                        Our proprietary algorithm utilizes cutting-edge time series analysis and machine learning to identify promising investment opportunities in the sports card market.
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-gray-600 mb-4">
                        <li>Analyzes historical trends across multiple card products and players</li>
                        <li>Identifies patterns in new product releases and their potential value</li>
                        <li>Considers factors such as player performance, media coverage, and collector sentiment</li>
                        <li>Provides recommendations for both specific cards and broader product lines</li>
                    </ul>
                    <p className="text-gray-600">
                        Whether you're interested in rookie cards, specific parallels, or entire sets, our Trend Analyzer gives you the insights needed to make informed investment decisions.
                    </p>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">How It Works</h2>
                    <div className="space-y-6">
                        <HowItWorksStep
                            icon={<Search className="h-8 w-8 text-blue-500" />}
                            title="Input Your Criteria"
                            description="Enter specific players, card types, or set your investment parameters."
                        />
                        <ArrowRight className="h-8 w-8 text-gray-400 mx-auto" />
                        <HowItWorksStep
                            icon={<BarChart2 className="h-8 w-8 text-blue-500" />}
                            title="Analyze Market Data"
                            description="Our algorithm processes vast amounts of historical and real-time market data."
                        />
                        <ArrowRight className="h-8 w-8 text-gray-400 mx-auto" />
                        <HowItWorksStep
                            icon={<TrendingUp className="h-8 w-8 text-blue-500" />}
                            title="Receive AI-Powered Insights"
                            description="Get personalized recommendations and trend forecasts to guide your investments."
                        />
                    </div>
                </div>

                <div className="bg-blue-600 text-white rounded-lg shadow-lg p-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">Ready to Access Trend Analyzer?</h2>
                    <p className="text-xl mb-6">Upgrade to a Pro membership to unlock this powerful tool and more!</p>
                    <button className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
                        See Pricing & Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

const TrendCard = ({ title, chart }) => (
    <div className="bg-gray-100 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <div className="bg-white h-48 rounded-md flex items-center justify-center">
            <img src={chart} alt="Price Trend Chart" className="max-w-full max-h-full" />
        </div>
    </div>
);

const MarketSentimentCard = () => (
    <div className="bg-gray-100 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Market Sentiment</h3>
        <div className="bg-white h-48 rounded-md p-4">
            <SentimentBar label="Bullish" percentage={75} color="green" />
            <SentimentBar label="Neutral" percentage={15} color="yellow" />
            <SentimentBar label="Bearish" percentage={10} color="red" />
        </div>
    </div>
);

const SentimentBar = ({ label, percentage, color }) => (
    <div className="flex justify-between items-center mb-2">
        <span>{label}</span>
        <div className="w-2/3 bg-gray-200 rounded-full h-4">
            <div className={`bg-${color}-500 h-4 rounded-full`} style={{ width: `${percentage}%` }}></div>
        </div>
        <span>{percentage}%</span>
    </div>
);

const RecentSalesCard = () => (
    <div className="bg-gray-100 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Recent Sales</h3>
        <div className="bg-white h-48 rounded-md overflow-y-auto p-2">
            <ul className="space-y-2">
                <li className="flex justify-between"><span>Bryce Young Prizm RC #301</span><span className="font-semibold">$89.99</span></li>
                <li className="flex justify-between"><span>Bryce Young Prizm Silver RC</span><span className="font-semibold">$199.99</span></li>
                <li className="flex justify-between"><span>Bryce Young Optic Rated Rookie</span><span className="font-semibold">$54.99</span></li>
                <li className="flex justify-between"><span>Bryce Young Donruss RC</span><span className="font-semibold">$29.99</span></li>
                <li className="flex justify-between"><span>Bryce Young Select RC</span><span className="font-semibold">$79.99</span></li>
            </ul>
        </div>
    </div>
);

const AIRecommendationsCard = () => (
    <div className="bg-gray-100 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">AI Recommendations</h3>
        <div className="bg-white h-48 rounded-md p-4 overflow-y-auto">
            <p className="font-semibold mb-2">Based on your parameters and price range, we recommend:</p>
            <ul className="list-disc pl-5 space-y-2">
                <li>Bryce Young Prizm Purple Wave RC</li>
                <li>CJ Stroud Optic Holo RC</li>
                <li>Anthony Richardson Select Tie-Dye RC</li>
            </ul>
            <p className="mt-4 text-sm text-gray-600">Our algorithm predicts these cards have high growth potential based on historical trends and time series data analysis.</p>
        </div>
    </div>
);

const HowItWorksStep = ({ icon, title, description }) => (
    <div className="flex items-center">
        <div className="flex-shrink-0 mr-4">{icon}</div>
        <div>
            <h3 className="text-lg font-semibold">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default TrendAnalyzerPage;