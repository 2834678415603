import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import SmartBuybackPage from './pages/SmartBuybackPage';
import AIGradingPredictorPage from './pages/AIGradingPredictorPage';
import TrendAnalyzerPage from './pages/TrendAnalyzerPage';

function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow">
                    <Routes>
                        <Route exact path="/" element={<HomePage />} />
                        <Route path="/pricing" element={<PricingPage />} />
                        <Route path="/smart-buyback" element={<SmartBuybackPage />} />
                        <Route path="/ai-grading" element={<AIGradingPredictorPage />} />
                        <Route path="/trend-analyzer" element={<TrendAnalyzerPage />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;