import React, { useState } from 'react';
import { Check } from 'lucide-react';

const PricingPage = () => {
    const [annualBilling, setAnnualBilling] = useState(false);

    const plans = [
        {
            name: "Rookie",
            price: 0,
            features: [
                "1 AI PSA grade evaluation per month",
                "Basic market trends overview",
                "Community forum access"
            ],
            cta: "Start For Free"
        },
        {
            name: "Starter",
            price: annualBilling ? 99.99 : 9.99,
            features: [
                "5 AI PSA grade evaluations per month",
                "Limited access to Trend Analyzer",
                "Basic Smart Buyback access",
                "Weekly newsletter"
            ],
            cta: "Choose Starter"
        },
        {
            name: "Role Player",
            price: annualBilling ? 199.99 : 19.99,
            features: [
                "20 AI PSA grade evaluations per month",
                "Full access to Trend Analyzer",
                "Enhanced Smart Buyback service",
                "Weekly market insights newsletter",
                "Priority email support"
            ],
            cta: "Choose Role Player"
        },
        {
            name: "All-Star",
            price: annualBilling ? 599.99 : 59.99,
            features: [
                "Unlimited AI PSA grade evaluations",
                "Full access to all tools",
                "Personalized investment strategy",
                "Monthly consultation with pro card seller",
                "Early access to new features",
                "24/7 priority support"
            ],
            cta: "Choose All-Star"
        }
    ];

    return (
        <div className="bg-gradient-to-b from-blue-50 to-white py-20 px-4">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-bold mb-8 text-center text-gray-900">Choose Your Scouting Plan</h1>

                <div className="flex justify-center mb-12">
                    <div className="relative">
                        <div className="flex items-center">
                            <span className={`mr-3 ${!annualBilling ? 'font-bold' : ''} text-gray-700`}>Monthly</span>
                            <div
                                className={`w-14 h-8 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer duration-300 ease-in-out ${annualBilling ? 'bg-blue-600' : ''}`}
                                onClick={() => setAnnualBilling(!annualBilling)}
                            >
                                <div className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${annualBilling ? 'translate-x-6' : ''}`} />
                            </div>
                            <span className={`ml-3 ${annualBilling ? 'font-bold' : ''} text-gray-700`}>Annual</span>
                        </div>
                        {annualBilling && <span className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 text-sm text-green-600 whitespace-nowrap">Save up to 20%</span>}
                    </div>
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {plans.map((plan, index) => (
                        <div key={index} className={`bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 ${index === 2 ? 'border-4 border-blue-500 md:-mt-4 md:mb-4' : ''}`}>
                            <div className="p-6">
                                <h2 className="text-2xl font-bold mb-4 text-gray-900">{plan.name}</h2>
                                <p className="text-4xl font-bold mb-6 text-gray-900">
                                    ${plan.price}<span className="text-base font-normal text-gray-600">{plan.price > 0 ? annualBilling ? '/year' : '/month' : ' Forever'}</span>
                                </p>
                                <ul className="mb-6">
                                    {plan.features.map((feature, i) => (
                                        <li key={i} className="flex items-center mb-3 text-gray-700">
                                            <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="p-6 bg-gray-50">
                                <button className="w-full bg-blue-600 text-white rounded-md py-3 font-semibold hover:bg-blue-700 transition duration-300">
                                    {plan.cta}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-16 text-center">
                    <h2 className="text-2xl font-bold mb-4 text-gray-900">MVP Features Coming Soon!</h2>
                    <p className="text-gray-600">Stay tuned for our game-changing MVP tier, packed with exclusive features for the most dedicated collectors.</p>
                </div>
            </div>
        </div>
    );
};

export default PricingPage;