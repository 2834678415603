import React from 'react';
import { DollarSign, Truck, BarChart2, Shield, ArrowRight, Star, Check } from 'lucide-react';

const SmartBuybackPage = () => {
    const buybackOffers = [
        {
            company: "DTsportscards",
            location: "Arizona",
            payPercentage: 80,
            paymentMethods: ["Venmo", "Check"],
            processingTime: "2-3 business days"
        },
        {
            company: "DaCaptainCards",
            location: "Massachusetts",
            payPercentage: 84,
            paymentMethods: ["Cash App", "Venmo", "Check"],
            processingTime: "1-2 business days"
        },
        {
            company: "TopShelfCollectibles",
            location: "California",
            payPercentage: 82,
            paymentMethods: ["PayPal", "Venmo"],
            processingTime: "Same day"
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-20 px-4">
            <div className="max-w-6xl mx-auto">
                <div className="flex justify-center items-center mb-8">
                    <Star className="text-yellow-400 h-10 w-10 mr-2" />
                    <h1 className="text-4xl font-bold text-center text-gray-900">Smart Buyback</h1>
                    <Star className="text-yellow-400 h-10 w-10 ml-2" />
                </div>

                <div className="mb-12 text-center">
                    <p className="text-xl text-gray-600">Get instant offers from top wholesalers for your graded cards.</p>
                    <p className="text-lg text-blue-600 font-semibold mt-2">Exclusive Pro Feature</p>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12 relative overflow-hidden">
                    <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center z-10">
                        <div className="bg-red-600 text-white text-4xl font-bold py-4 px-8 rotate-45 transform translate-y-10 translate-x-10 shadow-lg">
                            Coming Soon
                        </div>
                    </div>

                    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Current Offers for Your Card</h2>
                    <div className="mb-6 p-4 bg-gray-100 rounded-lg">
                        <h3 className="text-xl font-semibold mb-2">Card Details:</h3>
                        <p>2020 Panini Prizm Justin Herbert Rookie Card - PSA 10</p>
                        <p>Current Market Value: $500</p>
                    </div>
                    <div className="space-y-6">
                        {buybackOffers.map((offer, index) => (
                            <div key={index} className="border rounded-lg p-4 hover:shadow-md transition duration-300">
                                <div className="flex justify-between items-center mb-2">
                                    <h3 className="text-xl font-semibold">{offer.company}</h3>
                                    <span className="text-gray-600">Location: {offer.location}</span>
                                </div>
                                <p className="text-2xl font-bold text-green-600 mb-2">
                                    Offer: ${(500 * offer.payPercentage / 100).toFixed(2)} ({offer.payPercentage}% of market value)
                                </p>
                                <p className="mb-2">Payment Methods: {offer.paymentMethods.join(", ")}</p>
                                <p className="mb-2">Processing Time: {offer.processingTime}</p>
                                <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300">
                                    Accept Offer
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Why Choose Our Smart Buyback?</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<DollarSign className="h-12 w-12 text-green-500" />}
                            title="Best Rates"
                            description="Pre-negotiated rates with top wholesalers ensure you get the best deals."
                        />
                        <FeatureCard
                            icon={<Truck className="h-12 w-12 text-blue-500" />}
                            title="Instant Payment"
                            description="Get paid quickly through various payment methods of your choice."
                        />
                        <FeatureCard
                            icon={<BarChart2 className="h-12 w-12 text-purple-500" />}
                            title="Market Insights"
                            description="Make informed decisions with our real-time market analysis."
                        />
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">How It Works</h2>
                    <div className="space-y-6">
                        <StepCard
                            icon={<Shield className="h-8 w-8 text-blue-500" />}
                            title="Submit Your Card Details"
                            description="Enter information about your graded card, including the grade and condition."
                        />
                        <StepCard
                            icon={<BarChart2 className="h-8 w-8 text-blue-500" />}
                            title="Receive Multiple Offers"
                            description="Get instant offers from our network of trusted wholesalers."
                        />
                        <StepCard
                            icon={<Check className="h-8 w-8 text-blue-500" />}
                            title="Choose Your Offer"
                            description="Select the best offer that suits your preferences."
                        />
                        <StepCard
                            icon={<DollarSign className="h-8 w-8 text-blue-500" />}
                            title="Get Paid Fast"
                            description="Receive payment through your chosen method quickly and securely."
                        />
                    </div>
                </div>

                <div className="bg-blue-600 text-white rounded-lg shadow-lg p-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">Ready to Access Smart Buyback?</h2>
                    <p className="text-xl mb-6">Upgrade to a Pro membership to unlock this feature and more!</p>
                    <button className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
                        See Pricing & Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => (
    <div className="flex flex-col items-center text-center">
        {icon}
        <h3 className="text-lg font-semibold mb-2 mt-4">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

const StepCard = ({ icon, title, description }) => (
    <div className="flex items-center">
        <div className="flex-shrink-0 mr-4">{icon}</div>
        <div>
            <h3 className="text-lg font-semibold">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default SmartBuybackPage;