import React from 'react';
import { Link } from 'react-router-dom';
import { BarChart2, Search, DollarSign, ChevronRight } from 'lucide-react';

const HomePage = () => {
    return (
        <div className="bg-gradient-to-b from-blue-50 to-white">
            {/* Hero Section */}
            <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
                <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 mb-4">
                    Your AI Scout for Sports Card Investments
                </h1>
                <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                    CardScout harnesses the power of AI to revolutionize your sports card investments. Analyze trends, predict grades, and maximize returns with cutting-edge technology.
                </p>
                <Link to="/pricing" className="bg-blue-600 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300 inline-flex items-center">
                    Start Scouting Today
                    <ChevronRight className="ml-2 h-5 w-5" />
                </Link>
            </section>

            {/* Features Section */}
            <section className="bg-white py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">AI-Powered Tools</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<BarChart2 className="h-12 w-12 text-blue-600" />}
                            title="Trend Analyzer"
                            description="Our AI predicts market trends to identify lucrative investment opportunities."
                            link="/trend-analyzer"
                        />
                        <FeatureCard
                            icon={<Search className="h-12 w-12 text-blue-600" />}
                            title="AI Grading Predictor"
                            description="Machine learning algorithms evaluate card conditions for accurate PSA grade predictions."
                            link="/ai-grading"
                        />
                        <FeatureCard
                            icon={<DollarSign className="h-12 w-12 text-blue-600" />}
                            title="Smart Buyback"
                            description="AI-assisted grading and pricing for a seamless selling experience."
                            link="/smart-buyback"
                        />
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="bg-blue-600 text-white py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">Ready to Revolutionize Your Card Investing?</h2>
                    <p className="text-xl mb-8">Join CardScout today and gain the edge in the sports card market.</p>
                    <Link to="/pricing" className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
                        See Our Pricing
                    </Link>
                </div>
            </section>
        </div>
    );
};

const FeatureCard = ({ icon, title, description, link }) => (
    <div className="flex flex-col items-center p-6 bg-gray-50 rounded-lg shadow-md">
        {icon}
        <h3 className="mt-4 text-xl font-semibold text-gray-900">{title}</h3>
        <p className="mt-2 text-gray-600 text-center">{description}</p>
        <Link to={link} className="mt-4 text-blue-600 hover:text-blue-800 font-medium">
            Learn More
        </Link>
    </div>
);

export default HomePage;