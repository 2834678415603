import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
                <div className="mb-4 md:mb-0">
                    <span className="text-2xl font-bold">CardScout</span>
                    <p className="mt-2 text-gray-400">© 2024 CardScout. All rights reserved.</p>
                </div>
                <div className="flex space-x-4">
                    <Link to="/privacy" className="hover:text-blue-400">Privacy Policy</Link>
                    <Link to="/terms" className="hover:text-blue-400">Terms of Service</Link>
                    <Link to="/contact" className="hover:text-blue-400">Contact Us</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;